<!--每日数据详情-->
<template>
    <div class="newDayDataInfo">
        <ul class="tab-wrpper">
            <li v-for="item in tabs" :key="item.name" @click="changeActiveItem(item)">
                <div class="tab-item">
                    <p><i class="iconfont icond"></i>{{ item.name }}</p>
                    <p class="num_p" @click.stop>
                        <span class="num">{{ item.num }}</span>方
                    </p>
                    <div class="active-tag" v-show="activeItem==item">
                        <i class="iconfont iconduigou"></i>
                    </div>
                </div>
            </li>
            <li class="total-item">
                <div class="tab-item">
                    <p><i class="iconfont icond"></i>汇总</p>
                    <p><span class="num">{{ opts.total }}</span>方</p>
                </div>
            </li>
        </ul>
        <div class="list">
            <table-list
                v-if="showTableList"
                ref="tableList"
                :extr="params"
                :code="params.code"
            ></table-list>
        </div>
    </div>
</template>

<script>
import tableList from '@/views/tableList/index';
export default {
    name: 'new-day-data-info',
    components: { tableList },
    data() {
        return {
            // 菜单列表
            opts: {
                //  销售方量
                mrsj_xsfl: {
                    list: [
                        { name: '正常办结&转工地', code: 'new_mrsj_xsfl', num: 0, prop: 'zcbj_zgd' },
                        { name: '按补目录数据', code: 'new_mrsj_arbl', num: 0, prop: 'arbl' },
                    ],
                    requestUrl: '/interfaceApi/report/tjfx/mrsj/new/xsflsktj',
                },
                //  生产方量
                mrsj_scfl: {
                    list: [
                        { name: '正常办结&转工地', code: 'mrsj_scfl_zcbj_zgd', num: 0, prop: 'zcbj_zgd' },
                        { name: '转机组原单', code: 'mrsj_scfl_zjz', num: 0, prop: 'zjz' },
                        { name: '退货转工地原单', code: 'mrsj_scfl_thzgd', num: 0, prop: 'thzgd' },
                        { name: '倒掉', code: 'mrsj_scfl_dd', num: 0, prop: 'dd' },
                        { name: '异常打料', code: 'mrsj_scfl_ycdl', num: 0, prop: 'ycdl' },
                    ],
                    requestUrl: '/interfaceApi/report/tjfx/mrsj/new/scflsktj',
                },
                //  调度派单量
                mrsj_ddpdfl: {
                    list: [
                        { name: '正常办结&转工地', code: 'mrsj_ddpdfl_zcbj_zgd', num: 0, prop: 'zcbj_zgd' },
                        // { name: '退货转工地', code: 'mrsj_ddpdfl_thzgd', num: 0, prop: 'thzgd' },
                        { name: '退货转工地原单', code: 'mrsj_ddpdfl_thzgdyd', num: 0, prop: 'thzgdyd' },
                    ],
                    requestUrl: '/interfaceApi/report/tjfx/mrsj/new/ddpdlsktj',
                },
                //  生产差方
                mrsj_sccf: {
                    list: [
                        { name: '正常办结&转工地', code: 'mrsj_sccf_zcbj_zgd', num: 0, prop: 'zcbj_zgd' },
                        { name: '转机组', code: 'mrsj_sccf_zjz', num: 0, prop: 'zjz' },
                        { name: '退货转工地', code: 'mrsj_sccf_thzgd', num: 0, prop: 'thzgd' },
                        { name: '倒掉', code: 'mrsj_sccf_dd', num: 0, prop: 'dd' },
                    ],
                    requestUrl: '/interfaceApi/report/tjfx/mrsj/new/sccfsktj',
                },
                total: 0,
            },
            // 激活的tab
            tabs: [],
            // 激活的选项
            activeItem: '',
            reqUrl: '',
            params: {
                code: {
                    TableCode: '',
                    QueryString: '',
                },
            },
            showTableList: true,
        };
    },
    props: {
        extr: {
            type: [Array, Object, String ],
        },
    },
    created() {
        this.initPage();
    },
    mounted() {
    },
    methods: {
        initPage() {
            this.tabs = this.opts[this.extr.code].list;
            this.reqUrl = this.opts[this.extr.code].requestUrl;
            this.activeItem = this.tabs[0];
            this.params.code.QueryString = this.extr.QueryString;
            this.params.code.TableCode = this.activeItem.code;
            this.getDataNum();
        },
        // 切换选项
        changeActiveItem(item) {
            this.activeItem = item;
            this.params.code.TableCode = this.activeItem.code;
        },
        // 获取生产方量
        getDataNum() {
            this.$axios.get(this.reqUrl + '?' + this.params.code.QueryString)
                .then(res => {
                    this.tabs.forEach(item => {
                        item.num = res[item.prop];
                    });
                    if (this.extr.code === 'mrsj_xsfl') {
                        this.opts.total = res.zcbj_zgd + res.arbl;
                    } else {
                        this.opts.total = res.total;
                    }
                });
        },
    },
};
</script>

<style lang="stylus" scoped>
.newDayDataInfo
    height 100%;
    .tab-wrpper
        padding 0.15rem
        background #fff
        display flex
        border-bottom 0.2rem solid #F0F0F0
        li
            border-radius 0.05rem;
            margin-right 0.2rem
            width: 2.5rem;
            height: 1rem;
            cursor pointer
            &:nth-child(1)
                background: linear-gradient(270deg, #55A7FE 0%, #3E79C5 100%);
                >>> .iconduigou
                    color: #3e79c5;
            &:nth-child(2)
                background: linear-gradient(270deg, #EFAB54 0%, #FA8C47 100%)
                >>> .iconduigou
                    color: #FA8C47
            &:nth-child(3)
                background: linear-gradient(260deg, #71C6B0 0%, #4EA496 100%);
                .iconduigou
                    color: #4EA496
            &:nth-child(4)
                background: linear-gradient(270deg, #F79187 0%, #EA5D5D 100%);
                .iconduigou
                    color: #EA5D5D;
            &:nth-child(5)
                background: linear-gradient(90deg, #576F94 0%, #7791B9 100%);
                .iconduigou
                    color: #7791B9;
            &.total-item
                border 1px solid #979EA7
                background #fff;
                .tab-item
                    color #666666
                    .num
                        color #333333
            .tab-item
                padding 0.2rem 0 0 0.3rem
                width: 100%;
                height: 100%;
                color #fff;
                background  url("./images/bg5.png")
                position relative
                overflow hidden
                p
                    margin-top -0.05rem
                    font-size 0.18rem
                    .icond
                        font-size 0.18rem
                        margin-right 0.05rem
                    .num
                        font-size 0.36rem
                        pointer-events none
                .num_p
                    display inline-block
                .active-tag
                    position: absolute;
                    right: -0.42rem;
                    top: -0.41rem;
                    width: 0.8rem;
                    height: 0.8rem;
                    background: rgba(255,255,255,0.5);
                    transform: rotate(
                        45deg
                    );
                    .iconfont
                        font-size: 0.16rem;
                        position: absolute;
                        left: 0.3rem;
                        bottom: 0.04rem;
                        transform: rotate(
                            -33deg
                        );
    .list
        height calc(100% - 1.5rem);
</style>
